import axios from "axios";
import { CONTESTS_API } from "../../../utils/constants/urls";

export default {
  getAll: async (userId) => {
    let response;
    if (userId) {
      response = await axios.get(`${CONTESTS_API}?user_id=${userId}`);
    } else {
      response = await axios.get(CONTESTS_API);
    }

    return response.data;
  },
};
