import React, { useState, useEffect } from "react";
import "./styles.scss";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import CustomizedSlider from "../Slider";
import RadioButton from "../RadioButton";
import {
  RECRUITERS_COUNT,
  EDIT_PROFILE_STEPS,
  SLIDER_MARKS,
  ROLES,
  REQUIREMENTS,
  LEVELS,
  JOB_REQUISITIONS,
  SKILL_SETS,
  PLACEMENTS_COUNT,
  INDUSTRIES,
} from "../../utils/constants/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SelectValidator from "../SelectValidator";
import MultiSelectValidator from "../MultiSelectValidator";
import { getCities } from "../../utils/constants/locations";
import { CircularProgress, FormControlLabel } from "@material-ui/core";
import StyledCheckbox from "../Checkbox";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  data: any;
  userStage: number;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

interface Background {
  recruitersCount: string;
  domesticRecruitementPeriod: number;
  expertiseList: string[];
  typesOfRequirements: string[];
  levelsList: string[];
  jobLocations: string[];
  skillsList: string[];
  industriesList: string[];
  jobRequisition: string[];
  jobRequisitionDetails: string;
  handledRecruitements: boolean;
  placements: string;
}

const initialize = () => {
  return {
    recruitersCount: "",
    domesticRecruitementPeriod: 0,
    expertiseList: [],
    typesOfRequirements: [],
    levelsList: [],
    jobLocations: [],
    skillsList: [],
    industriesList: [],
    jobRequisition: [],
    jobRequisitionDetails: "",
    handledRecruitements: false,
    placements: "",
  };
};

const MAX_COUNT = 5;
const MIN_SLIDER_VALUE = 1;
const MAX_SLIDER_VALUE = 10;
const DEFAULT_SLIDER_VALUE = 3;
const STEP_VALUE = 1;

const CompanyBackground = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    data,
    userStage,
    loading = false,
    error = {},
    disabled = true,
  } = props;
  const [companyBackground, setCompanyBackground] = useState(initialize);

  const validationSchema = Yup.object().shape({
    recruitersCount: Yup.string()
      .trim()
      .required("Select how many Recruiters your company hold at the moment"),
    expertiseList: Yup.array().min(1, "Select functional roles"),
    typesOfRequirements: Yup.array().min(1, "Select types of requirements"),
    levelsList: Yup.array().min(1, "Select levels your company hire talent"),
    industriesList: Yup.array().min(
      1,
      "Select industries of your recruitment expertise"
    ),
    jobRequisition: Yup.array().required("Select type of job requisition"),
    jobRequisitionDetails: Yup.string()
      .trim()
      .notRequired()
      .default("")
      .test({
        name: "jobRequisitionDetails",
        message: "Other job requisition cannot be empty",
        test: function (value) {
          value = value.trim();
          if (this.parent.jobRequisition === "other" && value === "")
            return false;
          return true;
        },
      }),
    handledRecruitements: Yup.boolean().required(
      "Select whether you have handled Recruitment Drives"
    ),
    placements: Yup.string().trim().required("Select number of placements"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
    }),
    mode: "onBlur",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (data) {
      setCompanyBackground(data);
      Object.entries(data).map(([key, value]) => {
        setValue(key, value, { shouldValidate: userStage > currentStep });
      });
    }
  }, [data]);

  const changeHandler = (fieldName: string, value: any) => {
    setCompanyBackground({
      ...companyBackground,
      [fieldName]: value,
    });
    setValue(fieldName, value, {
      shouldValidate: true,
    });
    if (fieldName === "jobRequisition") {
      value !== "other" &&
        setValue("jobRequisitionDetails", "", {
          shouldValidate: true,
        });

      value === "other" &&
        setValue(
          "jobRequisitionDetails",
          companyBackground.jobRequisitionDetails,
          {
            shouldValidate: true,
          }
        );
    }
  };

  const onClickNext = (data: any) => {
    onNext(data);
  };

  const onClickBack = () => {
    gotoStep(currentStep - 1);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const stepNames = EDIT_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>

          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  How many Recruiters your company hold at the moment? *
                </label>
                {RECRUITERS_COUNT.map((item, index, arr) => {
                  if (!(index % 2)) {
                    return (
                      <div className="div-row-flex-short" key={index}>
                        <Button
                          label={item}
                          variant="option"
                          className="option-button"
                          {...register("recruitersCount")}
                          selected={companyBackground.recruitersCount === item}
                          onClick={(event) => {
                            changeHandler("recruitersCount", item);
                            event.preventDefault();
                          }}
                          disabled={disabled}
                        />
                        {arr[++index] && (
                          <Button
                            label={arr[index]}
                            variant="option"
                            className="option-button"
                            {...register("recruitersCount")}
                            selected={
                              companyBackground.recruitersCount === arr[index]
                            }
                            onClick={(event) => {
                              changeHandler("recruitersCount", arr[index]);
                              event.preventDefault();
                            }}
                            disabled={disabled}
                          />
                        )}
                      </div>
                    );
                  }
                })}
                <p className="invalid-feedback">
                  {errors.recruitersCount?.message}
                </p>
              </div>
            </div>
            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  How long your company been in domestic recruiting?
                </label>
                <CustomizedSlider
                  marks={SLIDER_MARKS}
                  min={MIN_SLIDER_VALUE}
                  max={MAX_SLIDER_VALUE}
                  step={STEP_VALUE}
                  defaultValue={DEFAULT_SLIDER_VALUE}
                  width={400}
                  sliderValue={companyBackground.domesticRecruitementPeriod}
                  onSliderChange={(event, value) =>
                    changeHandler("domesticRecruitementPeriod", value)
                  }
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="expertiseList"
                label={`Please select up to ${MAX_COUNT} functional roles that represent your company recruiting expertise *`}
                names={ROLES}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("expertiseList", values)}
                value={companyBackground.expertiseList}
                errorMessage={errors.expertiseList?.message.toString()}
                placeholder="E.g Administrative"
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="typesOfRequirements"
                label="Type of Requirements your company handled? *"
                names={REQUIREMENTS}
                maxCount={0}
                register={register}
                callback={(values) =>
                  changeHandler("typesOfRequirements", values)
                }
                value={companyBackground.typesOfRequirements}
                errorMessage={errors.typesOfRequirements?.message.toString()}
                placeholder="E.g Super Niche"
                showSelectAll={true}
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="levelsList"
                label="At what levels your company hire talent mostly? *"
                names={LEVELS}
                maxCount={0}
                register={register}
                callback={(values) => changeHandler("levelsList", values)}
                value={companyBackground.levelsList}
                errorMessage={errors.levelsList?.message.toString()}
                placeholder="E.g Senior Level"
                showSelectAll={true}
                disabled={disabled}
              />
            </div>
            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="jobLocations"
                label="Which job locations do your company recruit mostly? "
                names={getCities()}
                maxCount={0}
                register={register}
                callback={(values) => changeHandler("jobLocations", values)}
                value={companyBackground.jobLocations}
                errorMessage={errors.jobLocations?.message.toString()}
                placeholder="E.g Hyderabad"
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  What type of job requisitions your company recruit for? *
                </label>
                <div className="radiogroup-verticle">
                  {JOB_REQUISITIONS.map((requisition) => (
                    <FormControlLabel
                      key={requisition.key}
                      control={
                        <StyledCheckbox
                          name={requisition.value}
                          value={requisition.value}
                          onChange={(event) => {
                            const values = companyBackground.jobRequisition;
                            if (event.target.checked) {
                              values.push(event.target.value);
                            } else {
                              const removeIndex = companyBackground.jobRequisition.indexOf(
                                event.target.value
                              );
                              removeIndex !== -1 &&
                                values.splice(removeIndex, 1);
                            }
                            changeHandler("jobRequisition", values);
                          }}
                          checked={
                            companyBackground.jobRequisition.indexOf(
                              requisition.value
                            ) !== -1
                          }
                        />
                      }
                      label={requisition.label}
                      disabled={disabled}
                    />
                  ))}
                </div>
                {companyBackground.jobRequisition.indexOf("other") !== -1 ? (
                  <textarea
                    placeholder="Details of the other job requisitions here"
                    className="certificate requisitions-textarea"
                    value={companyBackground.jobRequisitionDetails}
                    onChange={(event) =>
                      changeHandler("jobRequisitionDetails", event.target.value)
                    }
                    disabled={disabled}
                  />
                ) : null}
                <p className="invalid-feedback">
                  {errors.jobRequisition?.message}
                  {errors.jobRequisitionDetails?.message}
                </p>
              </div>
            </div>

            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  Handled Recruitment Drives in the past? *
                </label>
                <div className="div-row-flex-short">
                  <Button
                    label="Yes"
                    variant="option"
                    className="option-button"
                    selected={companyBackground.handledRecruitements}
                    onClick={(event) => {
                      changeHandler("handledRecruitements", true);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                  <Button
                    label="No"
                    variant="option"
                    className="option-button"
                    selected={!companyBackground.handledRecruitements}
                    onClick={(event) => {
                      changeHandler("handledRecruitements", false);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                </div>
                <p className="invalid-feedback">
                  {errors.handledRecruitements?.message.toString()}
                </p>
              </div>
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="skillsList"
                label={`Please select top ${MAX_COUNT} Skill Sets that you specialize in sourcing talent`}
                names={SKILL_SETS}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("skillsList", values)}
                value={companyBackground.skillsList}
                errorMessage={errors.skillsList?.message.toString()}
                placeholder="E.g  Administrative"
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <SelectValidator
                label="How many placements your company has made in the last 12 months? *"
                labelclass="label-wrap-adjust"
                list={PLACEMENTS_COUNT}
                register={register}
                value={companyBackground.placements}
                callback={(event) =>
                  changeHandler("placements", event.target.value)
                }
                errorMessage={errors.placements?.message.toString()}
                placeholder="E.g 5 to 10"
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="industriesList"
                label={`Please select up to ${MAX_COUNT} industries that represent your recruiting expertise. *`}
                names={INDUSTRIES}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("industriesList", values)}
                value={companyBackground.industriesList}
                errorMessage={errors.industriesList?.message.toString()}
                placeholder="E.g Software and Technology"
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <Button
                label="Go Back"
                className="next-button"
                variant="outlined"
                onClick={onClickBack}
              />

              <Button
                label="Next"
                className="next-button"
                variant="primary"
                disabled={!isValid || disabled}
              />
            </div>
          </form>
          <p className={"invalid-feedback"}>{error?.message}</p>
          <div className="div-submit-button">
            <p>* All Mandatory Fields</p>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyBackground;
