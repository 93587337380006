import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "../Checkbox";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      "& .MuiInput-formControl": {
        paddingLeft: ".625rem",
        backgroundColor: "#FFFFFF",
      },
    },

    select: {
      width: "25.375rem",
      color: "#626880",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      margin: 0,
      backgroundColor: "#FFFFFF",
      border: ".09375rem solid #4D6CD9",
      borderRadius: 12,
      padding: ".1875rem",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },

    menuItem: {
      "&:hover": {
        backgroundColor: "#E9F0FF",
        borderRadius: ".5rem",
      },
      "&.Mui-selected": {
        backgroundColor: "#E9F0FF",
        borderRadius: ".5rem",
      },
      "&.Mui-selected:hover": { backgroundColor: "#E9F0FF" },
      paddingLeft: ".375rem",
      paddingTop: ".125rem",
      marginLeft: "1rem",
      marginRight: "1rem",
    },

    listItemText: {
      color: "#30374C",
      fontSize: "1rem",
      lineHeight: "1.375rem",
    },
  })
);

const gridStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "25.375rem",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "28.75rem",
    },
  })
);

const chipStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#4D6CD9",
      border: `.09375rem solid #4D6CD9`,
      backgroundColor: "#FBFBFB",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      borderRadius: 8,
      marginTop: "0.25rem",
      marginRight: "0.25rem",
      "&:hover": {
        backgroundColor: "#FBFBFB",
        boxShadow: `0rem .25rem 1.5rem rgba(77, 108, 217, 0.24)`,
      },
      "&:active": {
        backgroundColor: "#E8EAFA",
      },
      "&:disabled": {
        backgroundColor: "#FBFBFB",
        border: `.09375rem solid #A4A4A4`,
      },
    },

    label: {
      color: "#4D6CD9",
    },
  })
);

const deleteIconStyles = makeStyles(() =>
  createStyles({
    root: {
      color: "#4D6CD9",
      "&:hover": {
        color: "#4D6CD9",
      },
      "&:active": {
        color: "#4D6CD9",
      },
      "&:disabled": {
        color: "#FBFBFB",
      },
    },
  })
);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "31.25rem",
      width: "25.375rem",
      color: "#30374C",
      fontSize: "1rem",
      borderRadius: "1rem",
    },
  },
};

interface Props {
  names: string[];
  label?: string;
  callback?: (any) => void;
  maxCount?: number; //max number of items that can be selected
  value: string[];
  disabled?: boolean;
  register?: any;
  errorMessage?: any;
}

const MultipleSelect = (props: Props): JSX.Element => {
  const classes = useStyles();
  const gridClasses = gridStyles();
  const chipClasses = chipStyles();
  const deleteIconClasses = deleteIconStyles();
  const {
    label,
    callback,
    names,
    maxCount = 0, // default 0 indicates, unlimited number of selections are allowed
    value,
    disabled = false,
    errorMessage,
    register,
  } = props;
  const [selectedNames, setSelectedNames] = React.useState<string[]>(value);

  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {
          marginTop: theme.spacing(0),
        },
      },
      input: {
        width: 368,
        borderRadius: 12,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: ".075rem solid #AAB0CB",
        fontFamily: "Nunito",
        fontSize: 16,
        color: "#626880",
        padding: ".55rem .75rem",
        height: "1.276rem",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
      },
    })
  )(InputBase);

  const handleChange = (event) => {
    const options: string[] = event.target.value;
    if (maxCount == 0 || options.length <= maxCount) {
      setSelectedNames(options);
      callback(event);
    } else {
      setSelectedNames(options.slice(0, maxCount));
      event.preventDefault();
    }
  };

  const selectStyles = makeStyles(() =>
    createStyles({
      root: {
        "&:focus": {
          borderRadius: 12,
          borderColor: "#4D6CD9",
          boxShadow: "0rem .25rem 1rem rgba(206, 212, 240, 0.8)",
        },
      },
    })
  );

  const selectClasses = selectStyles();

  const handleDelete = (chipToDelete: string) => () => {
    setSelectedNames((chips) => chips.filter((chip) => chip !== chipToDelete));
    callback(event);
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-checkbox-label">{label}</InputLabel>
        <Select
          disableUnderline
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={selectedNames}
          onChange={handleChange}
          input={
            <BootstrapInput name={name} {...register} value={selectedNames} />
          }
          renderValue={(selected) => selectedNames.toString()}
          MenuProps={MenuProps}
          classes={{ root: selectClasses.root }}
          disabled={disabled}
        >
          <MenuItem
            key="null"
            value="null"
            selected={selectedNames.length === 0}
            className={classes.menuItem}
          >
            <ListItemText
              primary="Please select"
              classes={{ primary: classes.listItemText }}
            />
          </MenuItem>
          {names.map((name) => (
            <MenuItem key={name} value={name} className={classes.menuItem}>
              <Checkbox checked={selectedNames.indexOf(name) > -1} />
              <ListItemText
                primary={name}
                classes={{ primary: classes.listItemText }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={gridClasses.root}>
        <Grid container spacing={3}>
          <Grid item className={gridClasses.item}>
            {selectedNames.map((name: string, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                label={<span className={chipClasses.label}>{name}</span>}
                onClick={handleDelete(name)}
                onDelete={handleDelete(name)}
                deleteIcon={<ClearIcon className={deleteIconClasses.root} />}
                className={chipClasses.root}
                disabled={disabled}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <p className="invalid-feedback">{errorMessage}</p>
    </React.Fragment>
  );
};

export default MultipleSelect;
