export const SalesforceContent = '<!--  ----------------------------------------------------------------------  -->\n' +
    '<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->\n' +
    '<!--  If necessary, please modify the charset parameter to specify the        -->\n' +
    '<!--  character set of your HTML page.                                        -->\n' +
    '<!--  ----------------------------------------------------------------------  -->\n' +
    '<META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">\n' +
    '<!--  ----------------------------------------------------------------------  -->\n' +
    '<!--  NOTE: Please add the following <FORM> element to your page.             -->\n' +
    '<!--  ----------------------------------------------------------------------  -->\n' +
    '<form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">\n' +
    '<input type=hidden name="oid" value="00D5i000001uYTM">\n' +
    '<input type=hidden id="retURL" name="retURL" value="https://hiringhood.com">\n' +
    '<!--  ----------------------------------------------------------------------  -->\n' +
    '<!--  NOTE: These fields are optional debugging elements. Please uncomment    -->\n' +
    '<!--  these lines if you wish to test in debug mode.                          -->\n' +
    '<!--  <input type="hidden" name="debug" value=1>                              -->\n' +
    '<!--  <input type="hidden" name="debugEmail"                                  -->\n' +
    '<!--  value="venu.vaddella@hiringhood.com">                                   -->\n' +
    '<!--  ----------------------------------------------------------------------  -->\n' +
    '<label for="persona">Persona</label><input  id="00N5i00000IgObK" maxlength="255" name="00N5i00000IgObK" size="20" type="text" />\n' +
    '<label for="company">Company</label><input  id="company" maxlength="40" name="company" size="20" type="text" /><br>\n' +
    '<label for="first_name">First Name</label><input  id="first_name" maxlength="40" name="first_name" size="20" type="text" /><br>\n' +
    'Middle Name:<input  id="00N5i00000IfhC1" maxlength="100" name="00N5i00000IfhC1" size="20" type="text" /><br>\n' +
    '<label for="last_name">Last Name</label><input  id="last_name" maxlength="80" name="last_name" size="20" type="text" /><br>\n' +
    '<label for="title">Title</label><input  id="title" maxlength="40" name="title" size="20" type="text" /><br>\n' +
    '<label for="email">Email</label><input  id="email" maxlength="80" name="email" size="20" type="text" /><br>\n' +
    '<label for="phone">Phone</label><input  id="phone" maxlength="40" name="phone" size="20" type="text" /><br>\n' +
    'Phone Ext:<input  id="00N5i00000IfhCL" maxlength="10" name="00N5i00000IfhCL" size="20" type="text" /><br>\n' +
    'surveyAnswer:<textarea  id="00N5i00000IfhC6" name="00N5i00000IfhC6" type="text" wrap="soft"></textarea><br>\n' +
    'surveyAnswerOther:<input  id="00N5i00000IfhCB" maxlength="100" name="00N5i00000IfhCB" size="20" type="text" /><br>\n' +
    'Roles:<textarea  id="00N5i00000Igg39" name="00N5i00000Igg39" type="text" wrap="soft"></textarea>\n' +
    'Hiring Skills:<textarea  id="00N5i00000IfhCG" name="00N5i00000IfhCG" type="text" wrap="soft"></textarea><br>\n' +
    '<input id="submit" type="submit" name="submit">\n' +
    '</form>'