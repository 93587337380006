import * as React from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress, Dialog as MuiDialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";
import Button from "../Button";
import { PERSONA_REGISTRATION, HOTLIST } from "../../utils/constants/routes";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import * as Constants from "../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextValidator from "../TextValidator";
import axios from "axios";
import { useSelector } from "react-redux";
import { hotlistJobSelected } from "../../redux/selectors";
import { REGISTRATION_URL } from "../PersonaRegistration/api-urls";
import { CreateJobSeekerTable } from "../PersonaRegistration/site-data";
import jsxToString from "jsx-to-string";

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiButtonBase-root": {
    position: "absolute",
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function JSConnectDialog(props: any) {
  const { onClose } = props;
  const [open, setOpen] = React.useState(true);
  const [emailId, setEmailId] = React.useState("");
  const [personaVerified, setPersonaVerified] = React.useState(false);
  const [newUser, setNewUser] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const jobSelected = useSelector(hotlistJobSelected);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Email address is required")
      .matches(Constants.EMAIL_REGEX, "Email address is invalid"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailId(event.target.value);
    setValue(formItem, event.target.value, { shouldValidate: true });

    return;
  };

  const sendJSProfilesToHHAccountsManager = async (data: any) => {
    const payload = {
      emails: [process.env.REACT_APP_ACCOUNTS_MANAGER_EMAIL],
      subject: "Request for JS details",
      body: `<html><body>Hi Account Manager, <br/><br/> ${data.first_name.concat(
        " ",
        data.middle_name,
        data.last_name
      )} from ${
        data.organisation
      } has requested for the following Jobseeker details. You can reach them on their email address ${
        data.email_id
      } or phone number ${data.phone_number} <br/><br/><br/> ${jsxToString(
        CreateJobSeekerTable(jobSelected)
      )}<br/><br/>Regards, <br/>Team Hiringhood</body></html>`,
    };

    return await axios.post(
      "https://xak9-hweo-h6q9.n7.xano.io/api:8datCgfC:QA/send_email",
      payload
    );
  };

  const handleEmailVerify = async () => {
    setNewUser(false);
    const url = `${REGISTRATION_URL}?email_id=${emailId}`;
    localStorage.setItem("personaEmailId", emailId);
    setLoading(true);
    await axios
      .get(url)
      .then(async (response) => {
        if (response.status === 200) {
          setPersonaVerified(true);
          setLoading(false);
          await sendJSProfilesToHHAccountsManager(response.data);
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
        setPersonaVerified(false);
      });
  };

  return (
    <BootstrapDialog open={open}>
      <BootstrapDialogTitle onClose={handleClose}>
        Connect with Job Seeker
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {newUser && (
          <div className={"js-connect-dialog-body"}>
            <p>Please enter your email id to continue</p>
            <form>
              <TextValidator
                id="email"
                placeholder="Enter email id"
                label=""
                value={emailId}
                register={register}
                onChange={handleChange("email")}
                errorMessage={errors.email?.message}
                className={"js-connect-dialog-email-input"}
              />
            </form>
            <Button
              label="Continue"
              className={"js-connect-dialog-button"}
              disabled={!isValid}
              onClick={handleEmailVerify}
            />
          </div>
        )}
        {loading && (
          <div className={"js-connect-dialog-body"}>
            <CircularProgress size={32} />
          </div>
        )}
        {!loading && personaVerified ? (
          <div className="js-connect-dialog-body">
            <p>
              The shortlisted profile(s) will be shared by the Hiringhood
              Account Manager.
              <br />
              For further details, you can reach out to
              <br />
              account.manager1@hiringhood.com
            </p>
            <Link to={HOTLIST} className={"js-connect-dialog-body"}>
              <Button
                label="Done"
                className="js-connect-dialog-button"
                onClick={handleClose}
              />
            </Link>
          </div>
        ) : (
          !newUser &&
          !loading && (
            <div className="js-connect-dialog-body">
              <p>
                Email ID not found!
                <br />
                Please Register with Hiringhood to connect with Hotlist Job
                Seekers
              </p>
              <Link
                to={PERSONA_REGISTRATION}
                className={"js-connect-dialog-body"}
              >
                <Button label="Register" className="js-connect-dialog-button" />
              </Link>
            </div>
          )
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
