import { useState, useEffect } from "react";
import Slider from "../Slider";
import chevronDownImage from "../../assets/chevron-down.svg";
import chevronRightImage from "../../assets/chevron-right.svg";
import styles from "./styles.module.scss";

const SilderFilter = (props) => {
  const [expanded, setExpanded] = useState(props.isExpanded ? true : false);
  const {
    filterName,
    marks,
    min,
    max,
    step,
    range,
    list,
    field,
    onFilterChanged,
  } = props;

  const [rangeToShow, setRangeToShow] = useState(range);

  useEffect(() => {
    onFilterChanged(list);
  }, []);

  const handleSliderChange = (event, range) => {
    let updatedList = JSON.parse(JSON.stringify(list));
    updatedList = updatedList.filter((item) => {
      const leftLimit = Math.max(item[field][0], range[0]);
      const rightLimit = Math.min(item[field][1], range[1]);
      return leftLimit <= rightLimit;
    });
    setRangeToShow(range);
    onFilterChanged(updatedList);
  };

  return (
    <>
      <div className={styles["slider-filter-container"]}>
        <p className={styles["slider-filter-filter-title"]}>{filterName}</p>
        <img
          src={expanded ? chevronDownImage : chevronRightImage}
          className={styles["slider-filter-expand-icon"]}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </div>
      {expanded && (
        <div className={styles["slider-filter-filters"]}>
          <Slider
            marks={marks}
            min={min}
            max={max}
            step={step}
            defaultValue={range[0]}
            width={230}
            sliderValue={rangeToShow}
            onSliderChange={handleSliderChange}
          />
        </div>
      )}
    </>
  );
};

export default SilderFilter;
