import { GET_CONTESTS, setContests } from "../actions/contests";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/contests-parser";

const contestsFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_CONTESTS) {
    try {
      dispatch(setContests({ loading: true }));
      const contests = await api.contests.getAll(action.payload.userId);
      log("Successfully fetched the contests data");
      dispatch(setError(null));
      dispatch(setContests({ contestsData: parse(contests), loading: false }));
    } catch (error) {
      log("Error while fetching the contest details");
      dispatch(setContests({ loading: false }));
      const payload = {
        error,
        message: "Error while fetching the contest details",
      };
      dispatch(setError(payload));
    }
  }
};

export default [contestsFlow];
