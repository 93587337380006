import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";

interface Props {
  id: string;
  label: string;
  value: string;
  callback: any;
  register?: any;
  errorMessage?: any;
  format?: string;
  disabled?: boolean;
}

const Calendar = (props: Props): JSX.Element => {
  const {
    id,
    label,
    value,
    callback,
    register,
    errorMessage = "",
    format = "dd/MM/yyyy",
    disabled = false,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
      <div className="textfield-wrapper">
        <label htmlFor={id} className="active">
          {label}
        </label>
        <DatePicker
          InputProps={{
            disableUnderline: true,
          }}
          disabled={disabled}
          maxDate={new Date()}
          inputFormat={format}
          value={value}
          onChange={callback}
          views={["year", "month", "day"]}
          mask={"__/__/____"}
          renderInput={(params) => (
            <TextField
              {...params}
              id={id}
              {...register}
              value={value}
              variant="standard"
              sx={{
                "& .MuiInput-root": {
                  color: "#626880",
                  background: "#ffffff",
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                  fontFamily: ["Nunito", "sans-serif"],
                  height: "2.625rem",
                  paddingRight: ".9375rem",
                  paddingLeft: ".75rem",
                  border: "0.09375rem solid #aab0cb",
                  boxSizing: "border-box",
                  borderRadius: "0.75rem",
                  width: "25.375rem",
                  "&:focus": {
                    borderColor: "#4D6CD9",
                    boxShadow: "0rem .25rem 1rem rgba(206, 212, 240, 0.8)",
                  },
                },
              }}
            />
          )}
        />
        <p className="invalid-feedback">{errorMessage}</p>
      </div>
    </LocalizationProvider>
  );
};
export default Calendar;
