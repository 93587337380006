export const CreateJobSeekerTable = (jobSelected) => {
  return (
    <table data-border="1">
      <tr>
        <th>JobSeeker ID</th>
        <th>Role</th>
        <th>Experience</th>
        <th>Primary Skills</th>
        <th>Secondary Skills</th>
      </tr>
      {jobSelected.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.role}</td>
            <td>{item.experience}</td>
            <td>{item.primarySkill}</td>
            <td>{item.secondarySkills}</td>
          </tr>
        );
      })}
    </table>
  );
};
