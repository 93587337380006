import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import HeroImageContainer from "../../components/HeroImageContainer";
import Contests from "../../components/Contests";
import Error from "../../components/Error";
import { setActiveLink } from "../../redux/actions/menu";
import { getContests } from "../../redux/actions/contests";
import { getBanners } from "../../redux/actions/banners";
import {
  errorSelector,
  contestsSelector,
  contestsLoadingSelector,
  bannersSelector,
  bannersLoadingSelector,
} from "../../redux/selectors";
import "./styles.css";
import { getLocalStorage } from "../../utils/storage-manager";

const ContestsPage = () => {
  const dispatch = useDispatch();

  const contestsLoading = useSelector(contestsLoadingSelector);
  const bannersLoading = useSelector(bannersLoadingSelector);
  const recruiterId = getLocalStorage("userId", null);
  const loading = contestsLoading || bannersLoading;
  const error = useSelector(errorSelector);
  const contestData = useSelector(contestsSelector);
  const heroImages = useSelector(bannersSelector) || [];

  const [rerender, setRerender] = useState(false);

  function handleReset() {
    setRerender(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setActiveLink("Hiring Contests"));
    dispatch(getContests(recruiterId));
    if (rerender) {
      setRerender(false);
    }
    dispatch(getBanners());
  }, [dispatch, rerender]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div>
          <div className="contests-hero-image-container">
            <HeroImageContainer heroImages={heroImages} />
          </div>
          <div className="contests-contest-cards-container">
            <Contests contestsData={contestData} onReset={handleReset} />
          </div>
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default ContestsPage;
