import React from "react";
import "./styles.scss";
import InputMask from "react-input-mask";

interface Props {
  id?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label: string;
  register?: any;
  errorMessage?: any;
  labelClass?: string;
  ref?: any;
  mask?: string;
  disabled?: boolean;
}

const TextValidator = (props: Props): JSX.Element => {
  const {
    id,
    placeholder,
    value,
    className = "",
    onChange,
    label,
    register,
    errorMessage,
    labelClass = "",
    ref,
    mask,
    disabled = false,
  } = props;

  return (
    <div className="textfield-wrapper">
      <label htmlFor={id} className={value ? "active" : ""}>
        {label}
      </label>
      <InputMask
        value={value}
        onChange={onChange}
        mask={mask}
        maskChar=" "
        id={id}
        name={id}
        {...register}
        className={"input-text " + className}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
      />
      <p className="invalid-feedback">{errorMessage}</p>
    </div>
  );
};

export default TextValidator;
