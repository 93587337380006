import { useEffect, useState } from "react";
import "./styles.scss";
import TextValidator from "../../components/TextValidator";
import * as Constants from "../../utils/constants";
import PhoneNumberValidator from "../../components/PhoneNumberValidator";
import SelectValidator from "../../components/SelectValidator";
import RadioButton from "../RadioButton";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import { CircularProgress, Dialog as MuiDialog } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SalesforceContent } from "./salesforceContent";
import Background from "../../assets/login-background.svg";
import Card from "../../components/Card";
import { REGISTRATION_URL } from "./api-urls";
import axios from "axios";
import OtpInput from "react-otp-input";
import { CreateJobSeekerTable } from "./site-data";
import { useSelector } from "react-redux";
import { hotlistJobSelected } from "../../redux/selectors";
import MultipleSelect from "../MultipleSelect";
import jsxToString from "jsx-to-string";
import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitleProps } from "../JSConnectDialog";

const STRINGS = {
  PERSONA_REGISTRATION: {
    FOR_EMPLOYERS: "For Employers",
    REGISTRATION: "Registration",
    PERSONAS: {
      HEADER: "User Type",
      LABEL: "Select User Type *",
      PLACE_HOLDER: "Persona",
    },
    ORG_LEGAL_NAME: {
      HEADER: "Registered legal name of the Organisation",
      LABEL: "Organisation Name *",
      PLACE_HOLDER: "Organisation Legal Name",
    },
    SPOC: {
      HEADER: "SPOC Details",
      NAME: {
        HEADER: "Full name of SPOC *",
        LABEL: ["First Name *", "Middle Name", "Last Name *"],
        PLACE_HOLDER: ["First Name", "Middle Name", "Last Name"],
        ID: ["firstName", "middleName", "lastName"],
      },
      POSITION: {
        HEADER: "Official Position of SPOC",
        LABEL: "Enter Position *",
        PLACE_HOLDER: "Current Position / Designation",
      },
      EMAIL: {
        HEADER: "SPOC Official Email Address",
        LABEL: "Enter Email ID *",
        PLACE_HOLDER: "Official Email Address",
      },
      MOBILE: {
        HEADER: "SPOC Official Mobile Number",
        LABEL: "Enter Mobile Number *",
        PLACE_HOLDER: "Official Mobile Number",
      },
      ABOUT_US: {
        HEADER: "How did you come to know about us?",
        LABEL: "Select Option *",
        OPTIONS: [
          "Word of mouth",
          "Facebook",
          "LinkedIn",
          "Twitter",
          "Google Search",
          "Instagram",
          "Pinterest",
          "Hiringhood Website",
          "Others",
        ],
      },
      ROLES: {
        HEADER: "Which role you would like to hire immediately?",
        // todo: Change to freetext
        LABEL: "Mention Role(s) *",
      },
      SKILLS: {
        HEADER: "Which skills you would like to hire immediately?",
        // todo: change to freetext
        LABEL: "Mention Skill(s) *",
      },
      CAPTCHA: "Please check the box below to proceed",
      AGREEMENT:
        "I have read and agreed to the Terms of Use and Privacy Policy",
    },
  },
};

interface FormProps {
  formData: FormData;
  callbackFunction: any;
}

interface FormData {
  persona: string;
  otherPersona: string;
  orgLegalName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  position: string;
  email: string;
  phoneExt: string;
  phone: string;
  surveyAnswer: string;
  surveyAnswerOther: string;
  roles: string;
  rolesAnswerOther: string;
  hiringSkills: string;
}

const PersonaRegistration = () => {
  const formData = {
    persona: "employer",
    otherPersona: "",
    orgLegalName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    position: "",
    email: localStorage.getItem("personaEmailId"),
    phoneExt: "+91",
    phone: "",
    surveyAnswer: "",
    surveyAnswerOther: "",
    roles: "",
    rolesAnswerOther: "",
    hiringSkills: "",
  } as FormData;

  const jobSelected = useSelector(hotlistJobSelected);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [values, setValues] = useState(formData);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("employer");
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [registrationMessage, setRegistrationMessage] = React.useState("");

  // Email OTP States
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [disableVerify, setDisableVerify] = useState(true);
  const [isEmailOtpValid, setIsEmailOtpValid] = useState(false);
  const [waitOnEmailSendOtp, setWaitOnEmailSendOtp] = useState(false);
  const [waitOnEmailVerifyOtp, setWaitOnEmailVerifyOtp] = useState(false);
  const [sendEmailOtpError, setSendEmailOtpError] = useState("");
  const [verifyEmailOtpError, setVerifyEmailOtpError] = useState("");
  const [verifiedEmail, setVerifiedEmail] = useState("");

  // Phone OTP States
  const [phoneOtpSent, setPhoneOtpSent] = useState(false);
  const [isPhoneOtpValid, setIsPhoneOtpValid] = useState(false);
  const [waitOnPhoneSendOtp, setWaitOnPhoneSendOtp] = useState(false);
  const [waitOnPhoneVerifyOtp, setWaitOnPhoneVerifyOtp] = useState(false);
  const [sendPhoneOtpError, setSendPhoneOtpError] = useState("");
  const [verifyPhoneOtpError, setVerifyPhoneOtpError] = useState("");
  const [verifiedPhone, setVerifiedPhone] = useState("");

  const validationSchema = Yup.object().shape({
    persona: Yup.string().required("persona is required"),
    otherPersona: Yup.string().trim().notRequired().default("other"),
    orgLegalName: Yup.string()
      .required("Organisation legal name is required")
      .matches(
        Constants.ALPHA_NUMERIC_REGEX,
        "Invalid organisation legal name. Only alphanumeric characters allowed"
      ),
    firstName: Yup.string()
      .trim()
      .required("First name is required")
      .matches(
        Constants.ALPHA_NUMERIC_REGEX,
        "Invalid first name. Only alphanumeric characters allowed"
      ),
    middleName: Yup.string()
      .trim()
      .notRequired()
      .default("")
      .matches(
        Constants.ALPHA_NUMERIC_REGEX,
        "Invalid middle name. Only alphanumeric characters allowed"
      ),
    lastName: Yup.string()
      .trim()
      .required("Last name is required")
      .matches(
        Constants.ALPHA_NUMERIC_REGEX,
        "Invalid last name. Only alphanumeric characters allowed"
      ),
    position: Yup.string()
      .trim()
      .required("Position is required")
      .matches(
        Constants.ALPHA_NUMERIC_REGEX,
        "Invalid position. Only alphanumeric characters allowed"
      ),
    email: Yup.string()
      .trim()
      .required("Official email address is required")
      .matches(Constants.EMAIL_REGEX, "Email address is invalid"),
    phoneExt: Yup.string()
      .trim()
      .notRequired()
      .default("+91")
      .matches(Constants.INDIA_PHONE_EXT_REGEX, "Phone extension is invalid"),
    phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .matches(Constants.PHONE_REGEX, "Phone number is invalid"),
    surveyAnswer: Yup.string()
      .default(values.surveyAnswer)
      .required("Please select an option"),
    surveyAnswerOther: Yup.string().trim().notRequired().default(""),
    roles: Yup.string()
      .default(values.roles)
      .required("Please select any role from list"),
    rolesAnswerOther: Yup.string().trim().notRequired().default(""),
    hiringSkills: Yup.string().trim().required("Hiring skill(s) required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  useEffect(() => {
    Object.keys(values).map((key) => {
      setValue(key, values[key]);
    });
  }, []);

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [formItem]: event.target.value });
    setValue(formItem, event.target.value, { shouldValidate: true });
    return;
  };

  const handleOtp = (otp: string) => {
    setOtp(otp);
    if (otp.length === 4) {
      setDisableVerify(false);
    } else {
      setDisableVerify(true);
    }
  };

  const registerPersona = async () => {
    await axios
      .post(REGISTRATION_URL, {
        email_id: values.email,
        persona: values.persona,
        phone_number: values.phoneExt + values.phone,
        first_name: values.firstName,
        middle_name: values.middleName,
        last_name: values.lastName,
        organisation: values.orgLegalName,
      })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const sendJSProfilesToSales = async () => {
    const payload = {
      emails: [process.env.REACT_APP_SALES_EMAIL],
      subject: "Request for JS details",
      body: `<html><body>Hi Sales Manager, <br/><br/> ${values.firstName.concat(
        " ",
        values.middleName,
        values.lastName
      )} from ${
        values.orgLegalName
      } has requested for the following Jobseeker details. You can reach them on their email address ${
        values.email
      } or phone number ${values.phoneExt}  ${
        values.phone
      } <br/><br/><br/>${jsxToString(
        CreateJobSeekerTable(jobSelected)
      )}<br/><br/>Regards, <br/>Team Hiringhood</body></html>`,
    };

    return await axios.post(
      "https://xak9-hweo-h6q9.n7.xano.io/api:8datCgfC:QA/send_email",
      payload
    );
  };

  const onDeclaration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  };

  const onClickProceed = async () => {
    setSubmitConfirmation(true);
    localStorage.removeItem("personaEmailId");
    setRegistrationMessage("Registering...");
    setLoading(true);
    setSalesForceData();
    await registerPersona();
    setRegistrationMessage("Sending JS Profiles to Sales...");
    await sendJSProfilesToSales();
    setLoading(false);
    setRegistrationMessage("Registration Successful");
  };

  const handleClose = () => {
    let returnURL = window.location.href.split("/register/persona")[0];
    if (returnURL.includes("localhost")) {
      returnURL = "https://dev.hiringhood.com/hotlist";
    } else {
      returnURL = returnURL + "/hotlist";
    }
    (document.getElementById("retURL") as HTMLInputElement).value = returnURL;
    (document.getElementById("submit") as HTMLInputElement).click();
    setOpen(false);
  };

  const setSalesForceData = () => {
    (document.getElementById("00N5i00000IgObK") as HTMLInputElement).value =
      values.persona;
    (document.getElementById("company") as HTMLInputElement).value =
      values.orgLegalName;
    (document.getElementById("first_name") as HTMLInputElement).value =
      values.firstName;
    (document.getElementById("00N5i00000IfhC1") as HTMLInputElement).value =
      values.middleName;
    (document.getElementById("last_name") as HTMLInputElement).value =
      values.lastName;
    (document.getElementById("title") as HTMLInputElement).value =
      values.position;
    (document.getElementById("email") as HTMLInputElement).value = values.email;
    (document.getElementById("00N5i00000IfhCL") as HTMLInputElement).value =
      values.phoneExt;
    (document.getElementById("phone") as HTMLInputElement).value = values.phone;
    (document.getElementById("00N5i00000IfhC6") as HTMLInputElement).value =
      values.surveyAnswer;
    (document.getElementById("00N5i00000IfhCB") as HTMLInputElement).value =
      values.surveyAnswerOther;
    (document.getElementById("00N5i00000Igg39") as HTMLInputElement).value =
      values.roles;
    if (values.roles === "Other") {
      (document.getElementById("00N5i00000Igg39") as HTMLInputElement).value =
        values.rolesAnswerOther;
    }
    (document.getElementById(
      "00N5i00000IfhCG"
    ) as HTMLInputElement).value = values.hiringSkills.toString();
  };

  const checkDisabled = () => {
    let personaOtherDisabled = false;
    if (values.persona === "Other" && values.otherPersona === "") {
      personaOtherDisabled = true;
    }

    let surveyAnswerOtherDisabled = false;
    if (values.surveyAnswer === "Other" && values.surveyAnswerOther === "") {
      surveyAnswerOtherDisabled = true;
    }

    let rolesAnswerOtherDisabled = false;
    if (values.roles === "Other" && values.rolesAnswerOther === "") {
      rolesAnswerOtherDisabled = true;
    }

    return (
      !isValid ||
      !termsAgreed ||
      !values.orgLegalName ||
      !values.firstName ||
      !values.lastName ||
      !values.position ||
      personaOtherDisabled ||
      surveyAnswerOtherDisabled ||
      rolesAnswerOtherDisabled
    );
  };

  const handleUserType = (e) => {
    setUserType(e.target.value);
    setValues({ ...values, persona: e.target.value });
    if (e.target.value === "employer") {
      setValues({ ...values, otherPersona: "", persona: "employer" });
    }
  };

  const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      position: "absolute",
    },
  }));

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const renderPage = () => {
    return (
      <div className={"employer-registration-form-container"}>
        <form onSubmit={handleSubmit(onClickProceed)}>
          <p className={"form-row-header org-legal-name"}>
            {STRINGS.PERSONA_REGISTRATION.PERSONAS.HEADER}
          </p>

          <div className={"form-row"}>
            <div className="employer-registration-div-field-survey info-dropdown">
              <div className={"user-type"}>
                <RadioButton
                  name="persona"
                  label="Employer"
                  checked={userType === "employer"}
                  callback={handleUserType}
                  value="employer"
                ></RadioButton>
                <RadioButton
                  name="persona"
                  label="Other"
                  checked={userType === "Other"}
                  callback={handleUserType}
                  value="Other"
                ></RadioButton>
              </div>
              {userType === "Other" && (
                <TextValidator
                  id="otherPersona"
                  className="form-row-input"
                  label=""
                  placeholder="Enter other user type"
                  value={values.otherPersona}
                  register={register}
                  onChange={handleChange("otherPersona")}
                  errorMessage={errors.otherPersona?.message}
                />
              )}
            </div>
          </div>

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.HEADER}
          </p>
          <div className={"form-row"}>
            <p>{STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.LABEL}</p>
            <TextValidator
              id="orgLegalName"
              label=""
              placeholder={
                STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.PLACE_HOLDER
              }
              value={values.orgLegalName}
              register={register}
              onChange={handleChange("orgLegalName")}
              errorMessage={errors.orgLegalName?.message}
            />
          </div>

          <div>
            <p className={"form-row-header"}>
              {STRINGS.PERSONA_REGISTRATION.SPOC.HEADER}
            </p>
            {STRINGS.PERSONA_REGISTRATION.SPOC.NAME.ID.map((name, idx) => (
              <div className={"form-row"} key={idx}>
                <label>
                  {STRINGS.PERSONA_REGISTRATION.SPOC.NAME.LABEL[idx]}
                </label>
                <TextValidator
                  id={name}
                  label=""
                  placeholder={
                    STRINGS.PERSONA_REGISTRATION.SPOC.NAME.PLACE_HOLDER[idx]
                  }
                  value={values[name]}
                  register={register}
                  onChange={handleChange(name)}
                  errorMessage={errors[name]?.message}
                />
              </div>
            ))}
          </div>

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.HEADER}
          </p>
          <div className={"form-row"}>
            <p>{STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.LABEL}</p>
            <TextValidator
              id={"position"}
              label=""
              placeholder={
                STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.PLACE_HOLDER
              }
              value={values.position}
              register={register}
              onChange={handleChange("position")}
              errorMessage={errors.position?.message}
            />
          </div>

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.HEADER}
          </p>
          {!emailOtpSent && (
            <>
              <div className={"form-row"}>
                <p>{STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.LABEL}</p>
                <div className={"verify-text"}>
                  <TextValidator
                    className={"verify-text-email"}
                    id={"emailAddress"}
                    label=""
                    placeholder={
                      STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.PLACE_HOLDER
                    }
                    value={values.email}
                    register={register}
                    onChange={handleChange("email")}
                    errorMessage={errors.email?.message}
                  />
                </div>
                {/* <div className={"verify-btn"}>
                  <Button
                    label="Verify"
                    onClick={handleSendOTP("email")}
                    disabled={
                      waitOnEmailSendOtp || !values.email || errors.email
                    }
                  />
                  {waitOnEmailSendOtp && <CircularProgress size={32} />}
                </div> */}
                <p className="register-error">{sendEmailOtpError}</p>
              </div>
            </>
          )}

          {emailOtpSent && !isEmailOtpValid && (
            <>
              <p className="register-enter-otp">{`Enter the OTP received on your email address: ${values.email}`}</p>
              <div className="register-email-container">
                <OtpInput
                  shouldAutoFocus
                  numInputs={4}
                  isInputNum
                  inputStyle={"register-otp-input"}
                  onChange={handleOtp}
                  value={otp}
                  isDisabled={waitOnEmailVerifyOtp}
                />
                {/* <div className="register-verify-otp-container">
                  <Button
                    label="Verify OTP"
                    className="register-verify-otp"
                    onClick={handleVerifyOTP("email")}
                    disabled={disableVerify || waitOnEmailVerifyOtp}
                  />
                  {!isEmailOtpValid && waitOnEmailVerifyOtp && (
                    <CircularProgress size={32} />
                  )}
                </div> */}
              </div>
              {verifyEmailOtpError && (
                <p className="register-error">{verifyEmailOtpError}</p>
              )}
            </>
          )}
          {isEmailOtpValid && (
            <div className={"otp-verification-done"}>
              {`Email : ${verifiedEmail}`}{" "}
              <DoneIcon
                color="success"
                style={{ marginLeft: "0.5rem", fontWeight: "bold" }}
              />
            </div>
          )}

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.HEADER}
          </p>
          {!phoneOtpSent && (
            <>
              <div className={"form-row"}>
                <p>{STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.LABEL}</p>
                <div className={"verify-text"}>
                  <PhoneNumberValidator
                    id="phoneNumber"
                    label=""
                    placeholder={
                      STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.PLACE_HOLDER
                    }
                    register={register}
                    phone={values.phone}
                    phoneExt={values.phoneExt}
                    onPhoneChange={handleChange("phone")}
                    onExtChange={handleChange("phoneExt")}
                    errorMessage={
                      errors.phoneExt
                        ? errors.phoneExt.message
                        : errors.phone?.message
                    }
                  />
                </div>
                {/* <div className={"verify-btn"}>
                  <Button
                    label="Verify"
                    onClick={handleSendOTP("mobile")}
                    disabled={
                      waitOnPhoneSendOtp ||
                      values.phoneExt.length !== 3 ||
                      values.phone.length !== 10
                    }
                  />
                  {waitOnPhoneSendOtp && <CircularProgress size={32} />}
                </div> */}
                <p className="register-error">{sendPhoneOtpError}</p>
              </div>
            </>
          )}

          {phoneOtpSent && !isPhoneOtpValid && (
            <>
              <p className="register-enter-otp">{`Enter the OTP received on your mobile phone: ${values.phone}`}</p>
              <div className="register-email-container">
                <OtpInput
                  shouldAutoFocus
                  numInputs={4}
                  isInputNum
                  inputStyle={"register-otp-input"}
                  onChange={handleOtp}
                  value={otp}
                  isDisabled={waitOnPhoneVerifyOtp}
                />
                {/* <div className="register-verify-otp-container">
                  <Button
                    label="Verify OTP"
                    className="register-verify-otp"
                    onClick={handleVerifyOTP("mobile")}
                    disabled={disableVerify || waitOnPhoneVerifyOtp}
                  />
                  {!isPhoneOtpValid && waitOnPhoneVerifyOtp && (
                    <CircularProgress size={32} />
                  )}
                </div> */}
              </div>
              {verifyPhoneOtpError && (
                <p className="register-error">{verifyPhoneOtpError}</p>
              )}
            </>
          )}
          {isPhoneOtpValid && (
            <div className={"otp-verification-done"}>
              {`Phone : ${verifiedPhone}`}{" "}
              <DoneIcon
                color="success"
                style={{ marginLeft: "0.5rem", fontWeight: "bold" }}
              />
            </div>
          )}

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.ABOUT_US.HEADER}
          </p>
          <div className={"form-row"}>
            <p>{STRINGS.PERSONA_REGISTRATION.SPOC.ABOUT_US.LABEL}</p>
            <div className="employer-registration-div-field-survey info-dropdown">
              <SelectValidator
                name="surveyAnswer"
                label=""
                value={values.surveyAnswer}
                list={Constants.SURVEY_LIST}
                callback={handleChange("surveyAnswer")}
                register={register}
                errorMessage={errors.surveyAnswer?.message}
              />
              {values.surveyAnswer.toLowerCase() === "other" && (
                <TextValidator
                  id="surveyAnswerOther"
                  className="form-row-input"
                  label=""
                  placeholder="Enter other answer"
                  value={values.surveyAnswerOther}
                  register={register}
                  onChange={handleChange("surveyAnswerOther")}
                  errorMessage={errors.surveyAnswerOther?.message}
                />
              )}
            </div>
          </div>

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.ROLES.HEADER}
          </p>
          <div className={"form-row"}>
            <p>{STRINGS.PERSONA_REGISTRATION.SPOC.ROLES.LABEL}</p>
            <div className="employer-registration-div-field-survey info-dropdown">
              <SelectValidator
                name="roles"
                label=""
                value={values.roles}
                list={Constants.TECHNOLOGY_ROLES}
                callback={handleChange("roles")}
                register={register}
                errorMessage={errors.roles?.message}
              />
              {values.roles.toLowerCase() === "other" && (
                <TextValidator
                  id="rolesAnswerOther"
                  className="form-row-input"
                  label=""
                  placeholder="Enter other role"
                  value={values.rolesAnswerOther}
                  register={register}
                  onChange={handleChange("rolesAnswerOther")}
                  errorMessage={errors.rolesAnswerOther?.message}
                />
              )}
            </div>
          </div>

          <p className={"form-row-header"}>
            {STRINGS.PERSONA_REGISTRATION.SPOC.SKILLS.HEADER}
          </p>
          <div className={"form-row"}>
            <p>{STRINGS.PERSONA_REGISTRATION.SPOC.SKILLS.LABEL}</p>
            <div className="employer-registration-div-field-survey info-dropdown">
              <MultipleSelect
                names={Constants.SKILL_SETS}
                value={[]}
                callback={handleChange("hiringSkills")}
                errorMessage={errors.hiringSkills?.message}
              />
            </div>
          </div>

          <div className="emp-registration-disclaimer-container">
            <div className="emp-disclamer-info-container">
              <Checkbox
                aria-labelledby="disclaimer-text"
                onChange={onDeclaration}
              />
              <p id="disclaimer-text">
                I have read and agreed to the{" "}
                <a
                  href={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Recruitment+Agency.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Privacy+Policy_HiringHood.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <Button
              variant="primary"
              label="Proceed"
              disabled={checkDisabled()}
            />
          </div>
          <p className={"invalid-feedback"}>{errors?.message}</p>
        </form>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="edit-profile-banner-container">
          <img
            className="edit-profile-banner"
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: "contain",
              position: "relative",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div className="edit-profile-heading-container">
            <p className="edit-profile-heading">Registration</p>
          </div>
        </div>
        <div className="edit-profile-background">
          <div className="edit-profile-card">
            <Card>{renderPage()}</Card>
          </div>
        </div>
      </div>
      <div
        className={"emp-salesforce-content"}
        dangerouslySetInnerHTML={{ __html: SalesforceContent }}
      />
      {submitConfirmation && (
        <BootstrapDialog open={open}>
          <BootstrapDialogTitle onClose={handleClose}>
            {registrationMessage}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {loading && (
              <div className={"js-connect-dialog-body"}>
                <CircularProgress size={32} />
              </div>
            )}
            {!loading && (
              <div className="js-connect-dialog-body">
                <p>
                  Thanks for reaching out to hiringhood and providing the
                  details.
                  <br />
                  Our sales team will reach out to you shortly.
                </p>
                <Button
                  label="Close"
                  className="js-connect-dialog-button"
                  onClick={handleClose}
                />
              </div>
            )}
          </DialogContent>
        </BootstrapDialog>
      )}
    </>
  );
};

export default PersonaRegistration;
