import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";

interface Props {
  label?: string;
  name?: string;
  value: string;
  list: string[];
  callback: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  register?: any;
  errorMessage?: any;
  disabled?: boolean;
  labelclass?: string;
}

const SelectValidator = (props: Props): JSX.Element => {
  const {
    label,
    name,
    callback,
    list,
    value,
    placeholder,
    register,
    errorMessage,
    disabled = false,
    labelclass = "",
  } = props;
  const [selected, setSelected] = useState(value === "" ? "none" : value);

  useEffect(() => {
    setSelected(value === "" ? "none" : value);
  }, [value]);

  const handleChange = (event) => {
    setSelected(event.target.value.toString());
    callback(event);
    event.preventDefault();
  };

  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {
          marginTop: theme.spacing(0),
        },
      },
      input: {
        width: 368,
        borderRadius: 12,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: ".075rem solid #AAB0CB",
        fontFamily: "Nunito",
        fontSize: 16,
        color: "#626880",
        padding: ".55rem .75rem",
        height: "1.276rem",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
      },
    })
  )(InputBase);

  const MenuProps = {
    width: "24.375rem",
    color: "#626880",
    fontSize: "1rem",
    "&:focus": {
      backgroundColor: "#E9F0FF",
    },
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      menuItem: {
        "&:hover": {
          backgroundColor: "#E9F0FF",
          borderRadius: ".5rem",
        },
        "&.Mui-selected": {
          backgroundColor: "#E9F0FF",
          borderRadius: ".5rem",
        },
        "&.Mui-selected:hover": { backgroundColor: "#E9F0FF" },
        paddingLeft: ".375rem",
        paddingTop: ".125rem",
        marginLeft: "1rem",
        marginRight: "1rem",
        marginTop: "0.25rem",
      },
    })
  );

  const selectStyles = makeStyles(() =>
    createStyles({
      root: {
        "&:focus": {
          borderRadius: 12,
          borderColor: "#4D6CD9",
          boxShadow: "0rem .25rem 1rem rgba(206, 212, 240, 0.8)",
        },
      },
    })
  );

  const classes = useStyles();
  const selectClasses = selectStyles();

  return (
    <div className="textfield-wrapper">
      <label
        htmlFor={name}
        className={
          selected !== "none" || placeholder
            ? "active " + labelclass
            : labelclass
        }
      >
        {label}
      </label>
      <Select
        disableUnderline
        input={<BootstrapInput name={name} {...register} value={selected} />}
        MenuProps={MenuProps}
        onChange={handleChange}
        value={selected}
        disabled={disabled}
        classes={{ root: selectClasses.root }}
      >
        <MenuItem key="0" disabled value="none" className={classes.menuItem}>
          {placeholder}
        </MenuItem>
        {list.map((item) => {
          return (
            <MenuItem value={item} key={item} className={classes.menuItem}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      <p className="invalid-feedback">{errorMessage}</p>
    </div>
  );
};

export default SelectValidator;
