import React from "react";
import "./styles.scss";

interface Props {
  name: string;
  value?: string;
  label: string;
  key?: number; // Required when rendered via array.map
  callback?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
}

const RadioButton = (props: Props): JSX.Element => {
  const {
    name,
    value,
    label,
    callback,
    key,
    checked = false,
    disabled = false,
  } = props;

  return (
    <label>
      <input
        type="radio"
        name={name}
        value={value}
        key={key}
        onChange={callback}
        checked={checked}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};
export default RadioButton;
