import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { toPng } from "html-to-image";
import { setActiveLink } from "../../redux/actions/menu";
import Hotlist from "../../components/Hotlist";
import Error from "../../components/Error";
import logo from "../../assets/hotlist-logo.png";
import {
  getHotlist,
  getHotlistAnalytics,
  getHotlistByIds,
} from "../../redux/actions/hotlist";
import {
  errorSelector,
  hotlistJobSelected,
  hotlistLoadingSelector,
  hotlistSelector,
} from "../../redux/selectors";
import { HOTLIST } from "../../utils/constants/routes";
import "./styles.css";
import Button from "../../components/Button";
import JSConnectDialog from "../../components/JSConnectDialog";

const HotlistPage = () => {
  const dispatch = useDispatch();
  const [rerender, setRerender] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [showDialog, setShowDialog] = useState(false);

  const handleConnect = () => {
    setShowDialog(true);
  };

  const onClose = () => {
    setShowDialog(false);
  };
  // @TODO Refactor to show the selected Count
  // const connectCount = useSelector(hotlistJobSelected);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setActiveLink("Hot-list"));

    dispatch(getHotlistAnalytics());
    if (rerender) {
      setRerender(false);
    }
  }, [dispatch, rerender]);

  const onReset = () => {
    setRerender(true);
  };

  const loading = useSelector(hotlistLoadingSelector);
  const error = useSelector(errorSelector);
  const { hotlistData = {}, analytics } = useSelector(hotlistSelector);

  const downloadImage = (id) => {
    toPng(document.getElementById(id), { quality: 1 }).then(function (dataUrl) {
      const link = document.createElement("a");
      link.download = `${id}.png`;
      link.href = dataUrl;
      link.click();
    });
  };

  const handleClick = (ids) => {
    dispatch(getHotlistByIds(ids));
    setActiveTab(1);
  };

  const onTabChange = () => {
    dispatch(getHotlist());
    setActiveTab(1);
  };

  return (
    <>
      {showDialog && <JSConnectDialog onClose={onClose} />}
      <div className="hotlist-hero-image-container">
        <div>
          <p className="hotlist-title">Hot-list</p>
          <p className="hotlist-title-text">
            Find the weekly updated list of the best talents who are looking
            forward to join you
          </p>
        </div>
        <img src={logo} alt="Logo" className="hotlist-logo" />
      </div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div className="hotlist-tabs">
          <p
            className={`hotlist-tab ${
              activeTab === 0 ? "hotlist-active-tab " : ""
            }`}
            onClick={() => {
              setActiveTab(0);
            }}
          >
            Summary
          </p>
          <p
            className={`hotlist-tab ${
              activeTab === 1 ? "hotlist-active-tab " : ""
            }`}
            onClick={() => {
              dispatch(getHotlist());
              setActiveTab(1);
            }}
          >
            Job Seekers&apos; Details
          </p>
        </div>
      )}
      {!loading && !error && analytics && activeTab === 0 && (
        <>
          <p className="hotlist-analytics-title">
            Here&rsquo;s a quick overview of the job seekers currently available
            on the platform in the given timeline grouped by the skill set
            across different experience ranges
          </p>
          <div className="hotlist-analytics-container">
            <>
              {analytics.map((item) => {
                return (
                  <div
                    key={item.label}
                    className="hotlist-analytics-lwd-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="hotlist-analytics-lwd-title">
                        {item.label}
                      </p>
                      <div
                        title="Download"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          downloadImage(item.label);
                        }}
                      >
                        <DownloadIcon color="action" />
                      </div>
                    </div>
                    <div id={item.label}>
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 800 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow className="hotlist-table-row-header">
                                <TableCell
                                  className="hotlist-table-header"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    color: "#4d6cd9",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Role
                                </TableCell>
                                <TableCell
                                  className="hotlist-table-header"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    color: "#4d6cd9",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Exp Range (2-5 yrs)
                                </TableCell>
                                <TableCell
                                  className="hotlist-table-header"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    color: "#4d6cd9",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Exp Range (6-8 yrs)
                                </TableCell>
                                <TableCell
                                  className="hotlist-table-header"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    color: "#4d6cd9",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Exp Range (9-12 yrs)
                                </TableCell>
                                <TableCell
                                  className="hotlist-table-header"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    color: "#4d6cd9",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Exp Range (12+ yrs)
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.data.map((row) => {
                                return (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "& > *": { borderBottom: "unset" },
                                    }}
                                    className="hotlist-table-row-body"
                                  >
                                    <TableCell
                                      className="hotlist-table-header"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell
                                      className="hotlist-table-header"
                                      style={{
                                        textAlign: "center",
                                        color: "#0000cc",
                                        textDecoration: "underline",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `${HOTLIST}`,
                                        }}
                                        onClick={() => {
                                          handleClick(row.juniorJSIds);
                                        }}
                                        title="Click here to see these Job Seekers"
                                      >
                                        {row.junior}
                                      </Link>
                                    </TableCell>
                                    <TableCell
                                      className="hotlist-table-header"
                                      style={{
                                        textAlign: "center",
                                        color: "#0000cc",
                                        textDecoration: "underline",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `${HOTLIST}`,
                                        }}
                                        onClick={() => {
                                          handleClick(row.seniorJSIds);
                                        }}
                                        title="Click here to see these Job Seekers"
                                      >
                                        {row.senior}
                                      </Link>
                                    </TableCell>
                                    <TableCell
                                      className="hotlist-table-header"
                                      style={{
                                        textAlign: "center",
                                        color: "#0000cc",
                                        textDecoration: "underline",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `${HOTLIST}`,
                                        }}
                                        onClick={() => {
                                          handleClick(row.leadJSIds);
                                        }}
                                        title="Click here to see these Job Seekers"
                                      >
                                        {row.lead}
                                      </Link>
                                    </TableCell>
                                    <TableCell
                                      className="hotlist-table-header"
                                      style={{
                                        textAlign: "center",
                                        color: "#0000cc",
                                        textDecoration: "underline",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <Link
                                        to={{
                                          pathname: `${HOTLIST}`,
                                        }}
                                        onClick={() => {
                                          handleClick(row.architectJSIds);
                                        }}
                                        title="Click here to see these Job Seekers"
                                      >
                                        {row.architect}
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </>
      )}
      {!loading && !error && hotlistData && activeTab === 1 && (
        <>
          <Hotlist
            hotlistData={hotlistData}
            hotlist={hotlistData.hotlist}
            onReset={onReset}
            handleConnect={handleConnect}
          />
        </>
      )}
      {!loading && (error || !hotlistData) && <Error />}
    </>
  );
};

export default HotlistPage;
