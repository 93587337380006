import React, { useState, useEffect } from "react";
import "./styles.scss";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import TextValidator from "../TextValidator";
import * as Constants from "../../utils/constants/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { CircularProgress } from "@material-ui/core";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  data: any;
  userStage: number;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

interface BankDetails {
  bankName: string;
  accountHolderName: string;
  accountNumber: string;
  branch: string;
  ifscCode: string;
}
interface BillingDetails {
  gstNumber: string;
  panNumber: string;
  tanNumber: string;
  bankData: BankDetails;
}

const initialise = () => {
  const billing: BillingDetails = {
    gstNumber: "",
    panNumber: "",
    tanNumber: "",
    bankData: {
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
    },
  };
  return billing;
};

const BillingDetails = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    data,
    userStage,
    loading = false,
    error = {},
    disabled = false,
  } = props;
  const [billingData, setBillingData] = useState(initialise);
  const [bankData, setBankData] = useState(billingData.bankData);

  const validationSchema = Yup.object().shape({
    gstNumber: Yup.string()
      .trim()
      .uppercase()
      .required("GST number is required")
      .matches(Constants.GST_REGEX, {
        message: "Invalid GST number, please enter again",
      }),
    panNumber: Yup.string()
      .trim()
      .uppercase()
      .required("PAN number is required")
      .matches(Constants.PAN_REGEX, {
        message: "Invalid PAN number, please enter again",
      }),
    tanNumber: Yup.string()
      .trim()
      .uppercase()
      .required("TAN number is required")
      .matches(Constants.TAN_REGEX, {
        message: "Invalid TAN number, please enter again",
      }),
    bankName: Yup.string().trim().required("Bank name is required"),
    accountHolderName: Yup.string()
      .required("Accountholder name is required")
      .matches(/^[a-zA-z0-9]/, { message: "Account holder name is invalid" })
      .min(3, "Account holder name is too short"),
    accountNumber: Yup.string()
      .trim()
      .required("Account number is required")
      .matches(Constants.ACCOUNT_NUMBER_REGEX, {
        message: "Invalid Account number, please enter again",
      }),
    branch: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(/^[a-zA-z]/, {
        message: "Branch name is not valid",
        excludeEmptyString: true,
      }),
    ifscCode: Yup.string()
      .trim()
      .uppercase()
      .required("IFSC code is required")
      .matches(Constants.IFSC_REGEX, {
        message: "Invalid IFSC code, please enter again",
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(validationSchema), mode: "onBlur" });

  useEffect(() => {
    if (data) {
      setBillingData(data);
      setBankData(
        data && data.bankData
          ? data.bankData
          : {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              branch: "",
              ifscCode: "",
            }
      );
      Object.entries(data).map(([key, value]) => {
        if (key === "bankData") {
          Object.entries(data.bankData).map(([key, value]) => {
            setValue(key, value, { shouldValidate: userStage > currentStep });
          });
        }
        setValue(key, value, { shouldValidate: userStage > currentStep });
      });
    }
  }, [data]);

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBillingData({ ...billingData, [formItem]: event.target.value });
    setValue(formItem, event.target.value, { shouldValidate: true });
    return;
  };

  const handleBankDataChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBankData({ ...bankData, [formItem]: event.target.value });
    setBillingData({
      ...billingData,
      ["bankData"]: bankData,
    });
    setValue(formItem, event.target.value, { shouldValidate: true });
    return;
  };

  const onClickBack = () => {
    gotoStep(currentStep - 1);
  };

  const onClickNext = (data: any) => {
    onNext(data);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const stepNames = Constants.EDIT_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>

          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <fieldset disabled={disabled}>
              <div className="div-row-flex">
                <TextValidator
                  id="gstNumber"
                  className="email-input"
                  label="Company GST Number*"
                  register={register}
                  value={billingData.gstNumber}
                  onChange={handleChange("gstNumber")}
                  errorMessage={errors.gstNumber?.message.toString()}
                />
              </div>

              <div className="div-row-flex">
                <TextValidator
                  id="panNumber"
                  className="email-input"
                  label="Company PAN Number*"
                  register={register}
                  value={billingData.panNumber}
                  onChange={handleChange("panNumber")}
                  errorMessage={errors.panNumber?.message.toString()}
                />
              </div>
              <div className="div-row-flex">
                <TextValidator
                  id="tanNumber"
                  className="email-input"
                  label="Company TAN Number*"
                  register={register}
                  value={billingData.tanNumber}
                  onChange={handleChange("tanNumber")}
                  errorMessage={errors.tanNumber?.message.toString()}
                />
              </div>

              <div className="div-row-flex">
                <p className="bank-details">Bank Details</p>
              </div>

              <div className="div-row-flex">
                <TextValidator
                  id="bankName"
                  className="email-input"
                  label="Bank Name*"
                  register={register}
                  value={bankData.bankName}
                  onChange={handleBankDataChange("bankName")}
                  errorMessage={errors.bankName?.message.toString()}
                />
              </div>
              <div className="div-row-flex">
                <TextValidator
                  id="accountHolderName"
                  className="email-input"
                  label="Account Holder Name*"
                  register={register}
                  value={bankData.accountHolderName}
                  onChange={handleBankDataChange("accountHolderName")}
                  errorMessage={errors.accountHolderName?.message.toString()}
                />
              </div>

              <div className="div-row-flex">
                <TextValidator
                  id="accountNumber"
                  className="email-input"
                  label="Account Number*"
                  register={register}
                  value={bankData.accountNumber}
                  onChange={handleBankDataChange("accountNumber")}
                  errorMessage={errors.accountNumber?.message.toString()}
                />
              </div>
              <div className="div-row-flex">
                <TextValidator
                  id="branch"
                  className="email-input"
                  label="Branch"
                  register={register}
                  value={bankData.branch}
                  onChange={handleBankDataChange("branch")}
                  errorMessage={errors.branch?.message.toString()}
                />
              </div>

              <div className="div-row-flex">
                <TextValidator
                  id="ifscCode"
                  className="email-input"
                  label="IFSC Code*"
                  register={register}
                  value={bankData.ifscCode}
                  onChange={handleBankDataChange("ifscCode")}
                  errorMessage={errors.ifscCode?.message.toString()}
                />
              </div>
              <div className="div-row-flex">
                <div className="div-row-flex">
                  <Button
                    label="Go Back"
                    className="next-button"
                    variant="outlined"
                    onClick={onClickBack}
                  />

                  <Button
                    label="Next"
                    className="next-button"
                    variant="primary"
                    disabled={!isValid}
                  />
                </div>
              </div>
            </fieldset>
          </form>
          <p className={"invalid-feedback"}>{error?.message}</p>
          <div className="div-submit-button">
            <p>* All Mandatory Fields</p>
          </div>
        </>
      )}
    </div>
  );
};

export default BillingDetails;
